import { createSlice } from '@reduxjs/toolkit';

const TennisGameContinue = createSlice({
  name: 'TennisGameContinue',
  initialState: {
    TennisGameContinue: false, // время в секундах
  },
  reducers: {
    updateTennisGameContinue(state, action) {
      state.TennisGameContinue = action.payload;
    },
  },
});

export const { updateTennisGameContinue } = TennisGameContinue.actions;
export default TennisGameContinue.reducer;

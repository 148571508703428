import React, {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Web3Modal from 'web3modal';
import { ethers, JsonRpcSigner } from 'ethers';
import { useTranslation } from 'react-i18next';
import AuthService from '../services/AuthService';
import { UserModel } from '../models/User';
import {
  BACKEND_URL,
  ROUTE_GET_FIRST_CARDS,
  ROUTE_MAIN,
} from '../constants/constants';
import authHeader from '../services/AuthHeaders';

const DefaultProps = {
  login: () => null,
  getCurrentUser: () => null,
  setUserInLocalStorage: (user: UserModel) => null,
  updateUser: () => null,
  user: null,
  logout: () => null,
  ConnectWalletHandler: () => null,
};

export interface AuthProps {
  login: (
    email_or_phone: string,
    password: string,
    country_code?: string
  ) => any;
  logout: () => void;
  getCurrentUser: () => void;
  updateUser: () => void;
  setUserInLocalStorage: (user: UserModel) => void;
  user: UserModel | null;
  ConnectWalletHandler: () => void;
}

export const AuthContext = createContext<AuthProps>(DefaultProps);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState(() => AuthService.getCurrentUser());

  useEffect(() => {
    axios.interceptors.request.use((config) => {
      config.headers.Authorization = authHeader();
      const cookies = document.cookie.split('; ').reduce((acc: any, cookie) => {
        const [name, value] = cookie.split('=');
        acc[name] = value;
        return acc;
      }, {});

      if (cookies.csrftoken) {
        config.headers['X-CSRFToken'] = cookies.csrftoken;
      }
      return config;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        const user = AuthService.getCurrentUser();
        const refreshToken = user?.refresh_token;

        if (
          error.response
          && error.response.status === 401
          && error.config
          && !error.config._retry
          && refreshToken
        ) {
          error.config._retry = true;

          const response = fetch(`${BACKEND_URL}/account/token/refresh/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refresh: refreshToken,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              const { token, ...otherParamsUser } = user;

              otherParamsUser.token = res?.access;

              setUserInLocalStorage(otherParamsUser);
              originalRequest.headers.Authorization = `Bearer ${res?.access}`;

              return axios(originalRequest);
            })
            .catch(() => {
              logout();
            });
          return response;
        }
        return Promise.reject(error);
      },
    );
  }, []);

  function setCookie(name: string, value: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  // async function setupWallet() {
  //   const web3Modal = new Web3Modal({
  //     network: 'mainnet',
  //     cacheProvider: true,
  //   });
  //   const connection = await web3Modal.connect();
  //   const provider = new ethers.BrowserProvider(connection);
  //   connection.on('accountsChanged', () => {
  //     logout();
  //   });

  //   connection.on('chainChanged', (chainId: any) => {
  //     console.log('chainChanged', chainId);
  //     if (chainId != '0x539') {
  //       alert('network not supported!');
  //     }
  //   });

  //   return provider.getSigner();
  // }

  // async function ConnectWalletHandler() {
  //   if (typeof window.ethereum !== 'undefined') {
  //     const signer = await setupWallet();

  //     await loginMetamask(signer);
  //   } else {
  //     alert(t('popup.errors.metamask'));
  //   }
  // }
  // const loginMetamask = async (signer: JsonRpcSigner) => {
  //   const address = await signer.getAddress();
  //   const meta = await axios
  //     .get(`${BACKEND_URL}/account/captcha/`, {
  //       params: {
  //         web3_address: address,
  //       },
  //     })
  //     .then(({ data }) => {
  //       signer
  //         .signMessage(data.data.captcha)
  //         .then((response) => {
  //           axios
  //             .post(`${BACKEND_URL}/account/login_metamask/`, {
  //               web3_address: address,
  //               signature: response,
  //             })
  //             .then(({ data }) => {
  //               console.log('new', data.new);
  //               setUserInLocalStorage(data);
  //               if (data.new) {
  //                 navigate(ROUTE_GET_FIRST_CARDS);
  //               } else {
  //                 navigate(`${ROUTE_MAIN}`);
  //               }
  //             });
  //         })
  //         .catch(() => alert('signature declined!'));
  //     });
  // };

  async function login(
    username: string,
    password: string,
    country_code?: string,
  ) {
    try {
      const response = await AuthService.login(
        username,
        password,
        country_code,
      );
      setUserInLocalStorage(response);
    } catch (error) {
      console.error('Error in login:', error);
      throw error; // Перебрасываем ошибку для дальнейшей обработки в .catch()
    }
  }

  async function updateUser() {
    try {
      const response = await axios.get(`${BACKEND_URL}/account/fullinfo/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const updatedUserData = { ...user, ...response.data };
      setUserInLocalStorage(updatedUserData);
    } catch (error) {
      console.error('Error updating user information:', error);
    }
  }

  function getCurrentUser() {
    return user;
  }

  function setUserInLocalStorage(user: any) {
    AuthService.setUserInLocalStorage(user);
    setUser(user);
  }

  function logout() {
    AuthService.logout();
    setUser(null);
    navigate('/login');
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        setUserInLocalStorage,
        getCurrentUser,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

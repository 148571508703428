import { memo, useContext, type FC } from 'react';
import type { TLearnCancelPopup } from './types';
import axios from 'axios';
import { BACKEND_URL, ROUTE_PROFILE } from '@constants/constants';
import { AuthContext } from '@context/AuthContext';
import './LearnCancelPopup.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LearnCancelPopup: FC<TLearnCancelPopup> = memo((props) => {
  const { isActive, setIsActive } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, updateUser } = useContext(AuthContext);

  const cancelLearn = () => {
    axios
      .get(`${BACKEND_URL}/tournaments/learning/skip_learning/`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then(() => {
        setIsActive(!isActive);
        updateUser();
        navigate(`${ROUTE_PROFILE}/${user?.id}`, { replace: false });
        localStorage.removeItem('RegisterOnTournament');
        localStorage.removeItem('ReplacedCard');
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {isActive && (
        <div className={`popup ${isActive && 'active'} popup-learning-cancel`}>
          <div className="popup_background" />
          <div className="popup_box popup__cancel-learning">
            <div className="popup_box-content popup__cancel-learning_content">
              <div className="popup_box-content-inner popup__cancel-learning_inner">
                <button
                  className="close_btn"
                  type="button"
                  onClick={() => setIsActive(!isActive)}
                >
                  X
                </button>
                <div className="popup-learning-cancel__title popup_box_title">
                  {t('tutorial.finishing')}
                </div>
                <div className="popup-learning-cancel__text popup_box_text">
                  {t('tutorial.finishing_q')}
                </div>
              </div>
            </div>
            <div className="buttons_container learning__buttons-cancel-container">
              <button
                className="button button-black button-nonbackground cancel-button"
                type="button"
                onClick={() => setIsActive(!isActive)}
              >
                {t('cancel')}
              </button>
              <button
                className="button button-white promo-button"
                type="button"
                onClick={cancelLearn}
              >
                {t('confirm')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default LearnCancelPopup;

import { memo } from 'react';
import './LinearProgress.scss';

const LinearProgress = memo((props: any) => {
  const color = props.color === 'text-white' ? 'white' : props.color === 'text-black' ? 'black' : props.color

  const { progress = 0 } = props;
  return (
    <div className="linear-progress" style={{ border: `0.5px solid ${color}` }}>
      <div className="linear-percent" style={{ width: `${progress}%`, backgroundColor: color }} />
    </div>
  );
});

export default LinearProgress;

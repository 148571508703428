import { useCallback, useMemo, useState } from 'react';

interface UseHoverBind {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

type UseHoverResult = [boolean, UseHoverBind];

export const useHover = (): UseHoverResult => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return useMemo(() => [
    isHovered,
    {
      onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave,
    },
  ], [isHovered, handleMouseEnter, handleMouseLeave]);
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Policy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <main className="pb-20 pt-20 flex flex-col items-center">
      <h2 className="rules__title">
        {t('policy.title')}
      </h2>
      <article className="rules__article policy__article">
        <section className="rules__article-section">
          <h2 className="rules__article-title">1. Foreword</h2>
          <p className="rules__article-description">
            NFT Tennis is a sports entertainment platform where sports fans (“you,” or “User”) can engage with their favorite athletes and build connections with the global sports community.
            We respect your privacy and are committed to providing a secure environment to our Users. This privacy policy (“Policy”) is intended to provide you with important information about how and why your data may be collected and processed by NFT Tennis (“we,” “us” or “NFT Tennis”). All Personal Data (defined below) will be processed pursuant to applicable law, including the European Union’s General Data Protection Regulation, federal and State law in the United States governing personal data protection, and other applicable data protection laws. This Policy is part of the Terms and Conditions, as it is set forth directly therein. Capitalized terms not directly defined here shall have the meaning given to them in the Terms and Conditions.
          </p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">2. Data Collected and Purposes</h2>
          <p className="rules__article-description">
            We collect and process a limited amount of Personal Data for specific purposes, such as allowing you to access the Services, making the Services fun and easy to use, improving your experience and preventing fraud. Whenever we collect your Personal Data, and for whatever purposes, we are committed to always collecting the minimum amount of Data that is necessary for the relevant purpose.
            This section provides more information regarding what Personal Data we collect, what we process that Personal Data for and the legal basis for each specific processing purpose.
          </p>
          <ul className="rules__article-list">
            <li>
              Access to and Use of the Services. NFT Tennis collects and processes Personal Data in order to allow you to access and use the different parts of the Services, including to facilitate the purchase of a Collectible, play the Game, or participate in our Affiliate or Referral Programs.
              <li>To purchase a Collectible on the Primary Market and/or to purchase, transfer, withdrawal and/or exchange a Collectible via the Marketplace, NFT Tennis collects your username, phone number, wallet public key or payment identifier and has access to part of your credit card number (i.e., the last four digits of the credit card that we have access to via payment services providers but do not directly collect or store).</li>
              <li>To facilitate participation in a Tournament, send prizes to the winners, publish leaderboards, and more, NFT Tennis processes your username, phone number, wallet public key and the prize(s) you have won (where applicable). NFT Tennis may publish your username and the prize(s) you have won on its Website, as part of the leaderboard presenting the results of the tournaments.</li>
              <li>
                To allow participation in our Affiliate or Referral program, NFT Tennis collects and processes your email address, phone number, username and wallet public key.
              </li>
              <li>The legal basis of this processing is contractual. We require this data in order to execute the Terms and Conditions, and any other applicable Additional Terms, that you agreed to when signing up for your User account.</li>
              <li>
                To play directly on the Application with your Game User friends, NFT Tennis will be able to access your phone contact book with your consent. NFT Tennis may also share your user name with the Game Users who have your contact information in their phone contacts. This possibility can be changed at any time in your user settings.
              </li>
              <li>The legal basis for this processing is your consent.</li>

            </li>
            <li>
              Terms Compliance; Website and Account Security. NFT Tennis collects certain device and browser information (user agent), browsing data, including IP address (from which information on your country/state of location can be inferred), account information (including your email address and phone number) and transaction details, to ensure the security of the Services and your User account, and to detect, prevent, investigate, and/or trace any attempt to violate our Terms and Conditions or any other Applicable Terms, such as by malicious hacking. Your phone number may be used by NFT Tennis for SMS account authentication by our partners. Your phone number will not be shared or sold to any third parties for marketing purposes. In the course of an investigation, NFT Tennis may take additional security measures, including but not limited to requesting additional supporting documents and/or information, or suspending or canceling the relevant transaction in the event the transaction cannot be verified. Where payment fraud has been investigated and confirmed, the relevant Data and other related information will be stored in NFT Tennis monitoring system and used for future verifications.
              <li>The legal basis of this processing is NFT Tennis legitimate interest. NFT Tennis has a legitimate interest in detecting, preventing, and investigating fraud to ensure a fair game for all users and to protect its Services and Users against potential credit card frauds.</li>

            </li>
            <li>
              User Requests and Communication. NFT Tennis collects and processes Personal Data, such as your username, contact details, and the content of your message (to the extent such content is considered Personal Data) when managing and following up on any questions and/or requests you may submit via our Help Center or other method.
              <li>
                The legal basis of this processing is contractual. Support is integral to the Services we provide, so we require this data in order to execute the Terms and Conditions, and any other applicable Additional Terms, that you agreed to when signing up for your User account.
              </li>
            </li>
          </ul>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">3. Blockchain Technology and Your Data</h2>
          <ul className="rules__article-list">
            <li>NFT. Blockchain is at the core of the Services that NFT Tennis provides. This technology in the form of non-fungible-tokens (NFT) creates the verifiable authenticity, scarcity, and interoperability of your Collectibles.</li>
            <li>
              Your data. Your wallet public key and the information related to your purchases, transfers, withdrawals, and exchanges of Collectibles are not stored on our AWS servers. No Personal Data of our Users is stored by NFT Tennis on the public blockchain. However, please note that if you withdraw your funds or a Collectible from your Wallet (for instance to transfer them to another service or to an external wallet), such transfer will involve the storage of the information related to this transfer on a public blockchain. All transfers to external wallets are subject to the relevant Third-Party Service’s privacy policy and/or terms of service, please make sure to review the privacy policies and practices of the third parties involved before you proceed with such transfer. As indicated in our terms and conditions NFT Tennis will not be held liable for any third-party content or service and disclaims all responsibility for any and all services provided to a User by a third-party service provider.

              <li>
                The legal basis of this processing is contractual. The blockchain is at the core of our services. This is what allows NFT Tennis to offer verifiable authenticity, scarcity, and interoperability of your Collectibles.
              </li>
            </li>
          </ul>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">4. Data Retention</h2>
          <ul className="rules__article-list">
            <li>
              Account Information. NFT Tennis will keep your Personal Data until the closure of your account. However, where required or allowed by applicable law, if NFT Tennis needs to retain certain Personal Data beyond the date of closure of your account, then the maximum applicable retention period will apply, pursuant to the applicable statutory limitation periods. This period will not exceed the period for which the Company must retain the Data.
            </li>
            <li>
              Website Use. Personal Data processed by or on behalf of NFT Tennis for the purposes of analytics or other study or analysis will not be retained by NFT Tennis after such studies have been completed and the relevant reports, outputs and/or insights have been produced.
            </li>
            <li>
              Access to the Services. Personal Data processed for the use of the Services will only be kept for fifteen (15) months from the date of the relevant activity.
            </li>
            <li>
              Blockchain Technology. Personal Data processed and logged on a blockchain in connection with your transactions on NFT Tennis will not be stored in an immutable way. Please see “Blockchain technology” section above to better understand what categories of information are logged on the public blockchain.
            </li>
            <li>
              Terms Compliance. Website and Account Security. Personal Data processed for fraud prevention will be kept for three (3) years after the relevant data has been included on an alert list. Personal Data processed for security matters will be kept for five (5) years from the last account login or suspicious activity.
            </li>
            <li>
              User Requests and Communication. For management of your questions and/or requests, NFT Tennis will retain your Personal Data for a maximum of three (3) years after the last time you reached out to us.
            </li>
          </ul>
          <p className="rules__article-description">Notwithstanding the above, NFT Tennis may store some Personal Data for a longer duration pursuant to its legitimate interests and applicable legal obligations. When the relevant retention period expires, NFT Tennis will delete your Personal Data or apply adapted measures to the relevant data to ensure that you can no longer be identified. If NFT Tennis concludes that it is not necessary to keep your Personal Data in its active database, it will archive the relevant Data. For all active and archived Data, NFT Tennis will ensure that access to the relevant Data is restricted to a limited number of persons with a genuine need to access it.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">5. Processors and Data Transfer</h2>
          <p className="rules__article-description">
            NFT Tennis may use Third-Party Service providers and other third parties (“Processors”) to facilitate, maintain, improve, analyze, and secure the Website, App, and Services. Processors may have access to Personal Data for the sole and exclusive purpose of carrying out the processing activity assigned to the relevant Processor by NFT Tennis. NFT Tennis ensures that the service providers have sufficient guarantees for the performance of the processing activity and comply with the applicable laws and regulations.
            <br />
            Personal Data may be processed outside the European Economic Area (“EEA”). When Data is transferred outside of the EEA, NFT Tennis will take all necessary precautions, and alternatively or cumulatively: (i) ensure that the European Commission has taken an adequacy decision regarding the country of destination, (ii) that model contractual clauses adopted by the European Commission or the supervisory authority have been signed with the recipient, (iii) that the recipient adhered to an approved code of conduct or certification mechanism, etc.
          </p>
          <p className="rules__article-description" />
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            6. Data Sharing
          </h2>
          <p className="rules__article-description">
            The Company shares your Personal Data with Third-Party Service providers and suppliers which assist the Company for fulfilling the purposes specified in this Policy.
            NFT Tennis’s API.
          </p>
          <p className="rules__article-description">At NFT Tennis, we are committed to providing an open platform for our community of Users and developers to build upon new services, games, or applications. To this end, we provide access to an Application Programming Interface (API) allowing authenticated and authorized users to access more conveniently to some public Users’ information published on NFT Tennis’s Website:</p>
          <ul className="rules__article-list">
            <li>
              Basic User information, including their username, avatar, and wallet address,
            </li>
            <li>
              User’s cards, achievements, and favorites,
            </li>
            <li>
              Users’ auctions, offers, purchases, sales, and notifications.
            </li>
          </ul>
          <p className="rules__article-description">
            Please note that the following information is never shared through our API:
          </p>
          <ul className="rules__article-list">
            <li>Email address,</li>
            <li>Future lineups and rewards,</li>
            <li>Claiming rewards.</li>
          </ul>
          <p className="rules__article-description">Third-Party Services</p>
          <ul className="rules__article-list">
            <li>Some content or features of the Website and/or App are provided by Third-Party Service providers. In order to facilitate your access to such content and/or features, NFT Tennis may share some of your Personal Data with Third-Party Services and suppliers. The Third-Party Service may ask you for additional Data. Whether or not integrated into the Website and/or App directly, these Third-Party Services collect and process any Data they receive pursuant to their relevant privacy policy, in that provider’s sole responsibility.</li>
            <li>Please note that the contractual arrangements between NFT Tennis and our Third-Party Service Providers (such as payments services providers or marketing partners) specify that both NFT Tennis and the Third-Party Service Provider will act as an “independent controller” as defined by the GDPR (or “Business” as defined by the CCPA) and must comply with all applicable data protection laws and regulations. Please consult the relevant privacy policy of the relevant Third-Party Service for more information.</li>
          </ul>
          <p className="rules__article-description">
            Partner Sports Leagues, Teams, and Clubs
          </p>
          <ul className="rules__article-list">
            <li>
              Our partner tennis leagues sportsmen, (“Partner(s)”) make NFT Tennis great. NFT Tennis may share your email address and username with these trusted Partners for the relevant Partner’s own direct marketing and promotional purposes. During User account registration, you can choose if you consent to us sharing such Data with our Partners or not. This is totally up to you - If you do not consent, we will not share the Data.
            </li>
            <li>
              If you do consent, please note that the contractual arrangements between NFT Tennis and its Partners specify that both NFT Tennis and each Partner will act as an “independent controller” as defined by the GDPR (or “Business” as defined by CCPA) and must comply with all applicable data protection laws and regulations. Please consult the relevant privacy policy of the relevant Partner for more information.
            </li>
            <p className="rules__article-description">
              Judicial and Governmental Authorities
            </p>
            <ul className="rules__article-list">
              <li>Where required by law or valid court order and to the extent legally permitted, NFT Tennis may share your Personal Data with competent courts and/or any other governmental and/or public authorities requesting access to your Personal Data.</li>
            </ul>
          </ul>
          <p className="rules__article-description">
            We take sharing your Data seriously. Whenever we share your Personal Data with any of the above recipients, we do so on a strictly need-to-know basis and only as necessary for fulfilling identified processing purposes.
          </p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            7. Use by Children
          </h2>
          <p className="rules__article-description">
            The Website, App, and Services are intended for use by individuals eighteen (18) years old or older. Pursuant to the Terms and Conditions, the Website, App and Services are not available for Users under 18 years old.
          </p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            8. Your Rights
          </h2>
          <p className="rules__article-description">
            Most applicable data privacy laws (including the European Union’s GDPR, and some American states’ laws, including but not limited to the CCPA) have granted individuals rights in connection with their Data and how that Data is processed. The below section is intended to inform you of your rights in connection with your Personal Data. Please note that all requests pursuant to this section will be processed in accordance with the applicable European laws (notably GDPR) disregarding your location or country of residence but our answers may be adapted to comply with local laws requirements.
          </p>
          <p className="rules__article-description">
            You have the right to access your Personal Data, correct it, move it, delete it and a right to restrict how your Data is processed. You also the right to specify instructions defining the way Personal Data shall be managed after your death.
          </p>
          <p className="rules__article-description">Requests</p>
          <ul className="rules__article-list">
            <li>
              To exercise your rights or make an enquiry regarding data privacy at NFT Tennis, you should make a request accompanied by proof of identity by mail addressed to NFT Tennis.
            </li>
            <li>
              NFT Tennis will attempt to answer your request or enquiry as soon as possible, and at the latest within one (1) month of receipt of the request. For complex requests, we reserve the right to extend this period to three (3) months.
            </li>
            <li>
              If you intend to request the deletion of your Personal Information you can use the method described above or simply request the deletion of your User account via the settings of your User account as described in NFT Tennis’s terms and conditions.
            </li>
            <li>
              The deletion of your account will result in the irreversible deletion of the Personal Data associated with the account. To ensure the respects of your rights without altering the history and traceability of each Collectible you owned, the username associated with the ownership of these Collectibles will be pseudonymized and replaced by a random series of alphanumeric characters.
            </li>
            <li>
              You must save any Collectibles and Cryptocurrencies stored on the Wallet to an external wallet before any deletion request. Any Collectibles and/or Cryptocurrency stored on your Wallet that have not been transferred out of your account at the date of deletion will be permanently irretrievable. NFT Tennis will not be responsible for the permanent loss of any Collectibles and/or Cryptocurrency that was not saved prior to a deletion request.
            </li>
          </ul>
          <p className="rules__article-description">
            Protecting your Personal Data and complying with the applicable data protection legal framework are two of NFT Tennis’s top priorities and we need your help to be successful. By agreeing to this Policy, you agree to keep your Data on NFT Tennis up-to-date, and only provide accurate and current Personal Data. Additionally, if you send us any information that would allow us to identify, directly or indirectly, any other individuals, you represent and warrant that, prior to sharing this information, such other natural persons have been provided with this Policy and, to the extent applicable, have consented to the processing of their Data.

          </p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            9. Cookies
          </h2>
          <ul className="rules__article-list">
            <li>
              When you browse our Website, cookies are placed on your terminal, either directly or after having obtained your consent when required by the regulations on cookies.
            </li>
            <li>
              Cookies are pieces of information placed on the Internet user's terminal equipment by the server of the website visited. They are used by a website to send information to the Internet user's browser and to allow this browser to send information back to the website of origin (for example, a session identifier or the choice of a language).
            </li>
            <li>
              Only the sender of a cookie can read or modify the information contained in it.
            </li>
            <li>
              There are different types of cookies:
              <li>
                session cookies that disappear as soon as you leave the Website;
              </li>
              <li>
                permanent cookies that remain on your terminal until their lifetime expires or until you delete them using your browser's functions.
              </li>
            </li>
            <li>
              You are informed that, during your visits to the Website, cookies may be installed on your terminal equipment.
            </li>
            <li>
              To learn more about the use of cookies on our Website, please see the cookie policy here: NFT Tennis Cookie Policy.
            </li>
          </ul>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            10. Security
          </h2>
          <p className="rules__article-description">
            NFT Tennis has implemented and maintains measures to protect the confidentiality, security, and integrity of your User account and Personal Data against unauthorized access and disclosure, modification, alteration, damage, accidental loss or accidental or illicit destruction, as well as against any other form of unauthorized processing or disclosure.
          </p>
          <p className="rules__article-description">
            We recommend you observe security best practices, including storing your account information securely and not sharing account information. Please note that our backup services will not make a backup of your data to your personal, local storage or drive; we recommend you use a secondary backup source and/or other best practices for the preservation of data. Additionally, we recommend you enable two-factors-authentication, choose a strong password and/or use a password manager for any and all password protected features of your User account and your User account itself.
          </p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            11. Updates to this Privacy Policy
          </h2>
          <p className="rules__article-description">
            NFT Tennis reserves the right to change this Policy as necessary, as the Service evolves or as required by applicable laws. Any material Policy changes will be communicated to you. When using the Services, you are deemed to have accepted the terms of the Policy, as published on our Website.
          </p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">
            12. Jurisdiction and Applicable Law
          </h2>
          <p className="rules__article-description">
            The Policy is governed and interpreted according to European law. However, if you are a consumer and resident of any European Union country you will benefit from mandatory provisions of, and legal rights available to you under, the laws of that country. Nothing in this Policy affects your rights as a consumer to rely on these local law mandatory provisions and legal rights.
          </p>
          <p className="rules__article-description">
            If you are a consumer and a resident of any other European Union member, you and NFT Tennis may also bring proceedings in that country.
          </p>
          <p className="rules__article-description">
            the EU General Data Protection Regulation and "Businesses" as defined in the California Consumer Privacy Act (CCPA). Company is not liable for any issues related to the content, delivery, or security of the SMS messages sent by these independent partners.

          </p>
        </section>
      </article>
    </main>
  );
};

export default Policy;

export default function authHeader(): string {
  const localstorageUser = localStorage.getItem('user');
  if (!localstorageUser) {
    return '';
  }
  const user = JSON.parse(localstorageUser);
  if (user && user.token) {
    return `Bearer ${user.token}`;
  }
  return '';
}

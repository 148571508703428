import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TennisGame from './ui/TennisGame';
import ChooseParameters from './ui/ChooseParameters';
import './Training.scss';

function Training() {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route index path="parameters" element={<ChooseParameters />} />
      <Route path="tennis-game" element={<TennisGame />} />
    </Routes>
  );
}

export default Training;

import {
  useState, useRef, useLayoutEffect, useEffect,
} from 'react';
import axios from 'axios';
import addplayer_card_icon from '../../img/cards/addplayer_card_icon.svg';
import { BACKEND_URL } from '../../constants/constants';

export function AddPlayerCard() {
  return (
    <div className="flex items-center justify-center w-full transition border border-solid cursor-pointer bg-opacity-30 border-green rounded-xl aspect-[1/1.6] bg-green hover:bg-opacity-15">
      <img src={addplayer_card_icon} alt="" />
    </div>
  );
}

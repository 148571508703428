import { createSlice } from '@reduxjs/toolkit';

const UserInputSlice = createSlice({
  name: 'learningContext',
  initialState: {
    isActivePopup: false,
  },
  reducers: {
    updataActivePopup(state, action) {
      state.isActivePopup = action.payload;
    },
  },
});

export const { updataActivePopup } = UserInputSlice.actions;
export default UserInputSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const UpgradeItemOrStaffSlice = createSlice({
  name: 'upgradeItemOrStaff',
  initialState: {
    upgradeItemOrStaff: false,
  },
  reducers: {
    updateUpgradeItemOrStaff(state, action) {
      state.upgradeItemOrStaff = action.payload;
    },
  },
});

export const { updateUpgradeItemOrStaff } = UpgradeItemOrStaffSlice.actions;
export default UpgradeItemOrStaffSlice.reducer;

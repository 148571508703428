import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <main className="pb-20 pt-20 flex flex-col items-center">
      <h2 className="rules__title">
        {t('conditions.title')}
      </h2>
      <article className="rules__article">
        <section className="rules__article-section">
          <h2 className="rules__article-title">Введение</h2>
          <p className="rules__article-description">Добро пожаловать на сайт "NFT Tennis". Пожалуйста, внимательно ознакомьтесь с условиями использования перед началом использования сайта.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Авторские права</h2>
          <p className="rules__article-description">Весь контент, включая тексты, фотографии, видео и графические изображения, защищен авторскими правами и принадлежит сайту "NFT Tennis" или его лицензиарам.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Лицензия на использование</h2>
          <p className="rules__article-description">Сайт предоставляет вам личную, неисключительную и непередаваемую лицензию на использование ресурсов сайта для личных, некоммерческих целей.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Ограничения</h2>
          <p className="rules__article-description">Запрещается копировать, воспроизводить, перепубликовывать или распространять любой контент сайта без предварительного письменного разрешения.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Ответственность</h2>
          <p className="rules__article-description">Сайт "NFT Tennis" не несет ответственности за любые прямые, косвенные, случайные убытки, возникшие в результате использования или невозможности использования сайта.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Изменения условий</h2>
          <p className="rules__article-description">Сайт "NFT Tennis" оставляет за собой право в любое время изменять условия использования без предварительного уведомления.</p>
        </section>
      </article>
    </main>
  );
};

export default TermsPage;

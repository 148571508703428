export const getFormatMoney = (number: number) => {
  if (number >= 100000 && number < 1000000) {
    return `${number.toString().slice(0, 3)} ${number.toString().slice(3)}`;
  }
  if (number >= 10000 && number < 100000) {
    return `${number.toString().slice(0, 2)} ${number.toString().slice(2)}`;
  }
  if (number >= 1000 && number < 10000) {
    return `${number.toString().slice(0, 1)} ${number.toString().slice(1)}`; 
  }

  return number
}
import { FC } from 'react';
import logoImage from '@img/logo.png';
import './NotFoundPage.scss';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="NotFoundPage">
      <div className="not-found__content">
        <Fade>
          <h2 className="not-found__title">{t('notFoundPage.title')}</h2>
        </Fade>
        <button className="shadow-rainbow btn-white w-fit" onClick={() => navigate('/')}>{t('notFoundPage.onMain')}</button>
      </div>
    </div>
  );
};

export default NotFoundPage;

import CharacteristicCard from '@components/Cards/CharacteristicCard';
import Pagination from '@components/Pagination';
import { BACKEND_URL, ROUTE_ACHIEVEMENTS, ROUTE_CARDS } from '@constants/constants';
import { AuthContext } from '@context/AuthContext';
import axios from 'axios';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const MyPlayers = memo((props: any) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();

  async function fetchItems() {
    const {data: itemsData} = await axios.get(`https://nft-tennis.com/api/nft/nft_card/?page=${currentPage}&page_size=24&user=${user.id}&on_sale=false`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
    setTotalPages(itemsData.pages)
    setData(itemsData.results)
  }


  useEffect(() => {
    fetchItems();
  }, [currentPage])

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-screen-xl flex flex-col gap-12 py-6 m-[0_auto] px-4">
      <div className="my-teams-links flex m-[0_auto] xl:gap-6 gap-2 items-center">
        <button
          className="my-teams-link px-2 py-2 font-bold text-black rounded-md xl:px-10 xl:py-3 gradient-green"
          type="button"
        >
          {t('my_team.players')}
        </button>
        <Link
          to="/my_teams"
          className="my-teams-link px-2 py-2 font-bold rounded-md xl:px-10 xl:py-3 ring-2 ring-inset ring-white"
          type="button"
        >
          {t('my_team.my_team')}
        </Link>
        <Link
          to={ROUTE_CARDS}
          className="my-teams-link px-2 py-2 font-bold rounded-md xl:px-10 xl:py-3 ring-2 ring-inset ring-white"
          type="button"
        >
          {t('my_team.collection')}
        </Link>
        <Link
          to={ROUTE_ACHIEVEMENTS}
          className="my-teams-link px-2 py-2 font-bold rounded-md xl:px-10 xl:py-3 ring-2 ring-inset ring-white"
          type="button"
        >
          {t('my_team.awards')}
        </Link>
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-6 gap-x-2 xl:gap-x-4 gap-y-6">
      {data && data.map((item, index) => (
        <div
          className="cursor-pointer flex-col"
          key={index}
        >
          <CharacteristicCard

            card={item}
            isWounded={Boolean(item?.trauma)}
        />
        <div className='flex item-center justify-center mt-2'>
          <div className='flex items-center justify-center w-16 h-16 mr-3 bg-slate-800 text-3xl rounded-lg hover:opacity-70'           onClick={() => {
            navigate(`${ROUTE_CARDS}/${item.id}/`);
         }}>
            <div>+</div>
          </div>
        </div>
      </div>
      ))}
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onChangePage={onChangePage}
          totalPages={totalPages}
        />
      )}
    </div>
  )
})

export default MyPlayers
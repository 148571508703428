import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TCancelGamePopup } from './types';
import './CancelGamePopup.scss';
import { useTranslation } from 'react-i18next';

const CancelGamePopup : FC<TCancelGamePopup> = (props) => {
  const {
    isActive,
    setIsActive,
    route,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {isActive
                && (
                  <div className={`popup ${isActive && 'active'} popup-learning-cancel`}>
                    <div className="popup_background" />
                    <div className="popup_box">
                      <div className="popup_box-content">
                        <div className="popup_box-content-inner">
                          <button className="close_btn" type="button" onClick={() => setIsActive(!isActive)}>X</button>
                          <div className="popup-learning-cancel__title popup_box_title">{t('training.end_game')}</div>
                          <div className="popup-learning-cancel__text popup_box_text">{t('training.end_game_q')}</div>
                        </div>
                      </div>
                      <div className="buttons_container">
                        <button className="button button-black button-nonbackground cancel-button" type="button" onClick={() => setIsActive(!isActive)}>
                          {t('cancel')}
                        </button>
                        <button
                          className="button button-white promo-button"
                          type="button"
                          onClick={() => {
                            localStorage.removeItem('trainableCardId');
                            localStorage.removeItem('startGame');
                            setIsActive(!isActive);
                            navigate(`${route}`, { replace: true });
                          }}
                        >
                          {t('complete')}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
    </>
  );
};

export default CancelGamePopup;

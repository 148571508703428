import { createSlice } from '@reduxjs/toolkit';

const friendlyMatchResult = createSlice({
  name: 'friendlyMatchResult',
  initialState: {
    firstRound: '-',
    secondRound: '-',
    thirdRound: '-',
    fourthRound: '-',
    fifthRound: '-',
    counterSet: '1',
  },
  reducers: {
    updateFirstRound(state, action) {
      state.firstRound = action.payload;
    },
    updateSecondRound(state, action) {
      state.secondRound = action.payload;
    },
    updateThirdRound(state, action) {
      state.thirdRound = action.payload;
    },
    updateFourthRound(state, action) {
      state.fourthRound = action.payload;
    },
    updateFifthRound(state, action) {
      state.fifthRound = action.payload;
    },
    updateSet(state, action) {
      state.counterSet = action.payload;
    },
  },
});

export const {
  updateFirstRound, updateSecondRound, updateThirdRound, updateFourthRound, updateFifthRound, updateSet,
} = friendlyMatchResult.actions;
export default friendlyMatchResult.reducer;

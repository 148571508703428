import { createSlice } from '@reduxjs/toolkit';

const isNewTeam = createSlice({
  name: 'isNewTeam',
  initialState: {
    isNewTeam: false,
  },
  reducers: {
    updateIsNewTeam(state, action) {
      state.isNewTeam = action.payload;
    },
  },
});

export const { updateIsNewTeam } = isNewTeam.actions;
export default isNewTeam.reducer;

import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoute() {
  if (typeof localStorage === 'undefined' || typeof window === 'undefined') {
    return null;
  }
  return (localStorage.user ? <Outlet /> : <Navigate to="/login" replace />);
}

export default ProtectedRoute;

import React, { memo } from 'react';
import classNames from 'classnames';
import type { TPagination } from './types';
import './Pagination.scss';

const Pagination: React.FC<TPagination> = memo((props) => {
  const { currentPage, onChangePage, totalPages } = props;

  const getDisplayedPages = () => {
    const totalPagesToShow = 5;
    const adjacentPages = Math.floor(totalPagesToShow / 2);
    
    if (totalPages <= totalPagesToShow) {
      // If total pages are less than or equal to the number of pages to show
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }

    // Calculate start and end page
    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, currentPage + adjacentPages);

    // Adjust startPage if there's not enough room on the left side
    if (currentPage - adjacentPages < 1) {
      endPage = Math.min(totalPages, endPage + (adjacentPages - (currentPage - 1)));
      startPage = 1;
    }

    // Adjust endPage if there's not enough room on the right side
    if (currentPage + adjacentPages > totalPages) {
      startPage = Math.max(1, startPage - (currentPage + adjacentPages - totalPages));
      endPage = totalPages;
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };

  const pages = getDisplayedPages();

  return (
    <div className="flex justify-center w-full gap-4">
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onChangePage(page)}
          className={classNames('font-bold text-2xl', {
            'text-gray-400': page !== currentPage,
            'text-grey-500': page === currentPage
          })}
        >
          {page}
        </button>
      ))}
    </div>
  );
});

export default Pagination;


import Loader from '@components/Loader';
import './PageLoader.scss';

function PageLoader(props: any) {
  return (
    <div className="page-loader" {...props}>
      <Loader />
    </div>
  );
}

export default PageLoader;

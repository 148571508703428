import { FC, memo } from 'react';
import usePopup from '@hooks/usePopup';
import type { TPopupBox } from './types';

const PopupBox: FC<TPopupBox> = memo((props) => {
  const {
    id, children, buttons, contentBlockClassName, navigate, route, className, onClose, notBublingBg,
  } = props;
  const { closePopup, openPopups } = usePopup();
  const isOpen = openPopups.includes(id);

  return (
    <>
      {/* <svg
        className="svg_mask_for_popup"
        width="1"
        height="1"
        viewBox="0 0 593 204"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipShape">
          <path
            d="M27.8487 16.369C29.3788 7.21081 37.3038 0.5 46.5889 0.5H574C584.493 0.5 593 9.00659 593 19.5V167.08C593 177.344 584.848 185.753 574.589 186.071L19.6452 203.282C7.66758 203.654 -1.65878 192.98 0.315977 181.16L27.8487 16.369Z"
            fill="#D81717"
          />
        </clipPath>
      </svg> */}
      <div
        className={`fixed w-screen h-screen px-2 top-0 left-0 z-[100] justify-center items-center overflow-x-hidden ${
          isOpen ? 'flex' : 'invisible'
        } ${className}`}
      >
        <div
          className="w-screen h-screen fixed top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,.4)] backdrop-blur"
          onClick={notBublingBg ? undefined : () => {
            onClose?.();
            closePopup(id);
            if (navigate) {
              navigate(`${route}`, { replace: true });
            }
          }}
        />
        <div className={`relative min-w-[300px] z-[90]${contentBlockClassName ? ` ${contentBlockClassName}` : ''}`}>
          <div className="p-4 xl:px-12 xl:py-6 rounded-xl" style={{ backgroundColor: 'rgb(82 82 82 / 0.3)' }}>
{!notBublingBg &&             (<div
              className="absolute text-white cursor-pointer top-4 right-4"
              onClick={() => {
                onClose?.();
                closePopup(id);
                if (navigate) {
                  navigate(`${route}`, { replace: true });
                }
              }}
            >
              X
            </div>)}
            <div>
              {children}
            </div>
          </div>
          <div className="mt-4">
            {buttons || (
              <button
                className="button button-white"
                onClick={() => {
                  closePopup(id);
                }}
              >
                ОК
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default PopupBox;

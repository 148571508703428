import { createSlice } from '@reduxjs/toolkit';

const Timer = createSlice({
  name: 'timer',
  initialState: {
    isTimerMinute: 180, // время в секундах
  },
  reducers: {
    updateTimerMinute(state, action) {
      state.isTimerMinute = action.payload;
    },
  },
});

export const { updateTimerMinute } = Timer.actions;
export default Timer.reducer;

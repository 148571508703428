import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './GameRulesPage.scss';

const GameRulesPage = memo(() => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <main className="pb-20 pt-20 flex flex-col items-center">
      <h2 className="rules__title">
        {t('rules.title')}
      </h2>
      <article className="rules__article">
        <section className="rules__article-section">
          <h2 className="rules__article-title">Общие положения</h2>
          <p className="rules__article-description">Большой теннис — это спортивная игра, в которой могут участвовать два (одиночный матч) или четыре (парный матч) игрока. Игра проводится на прямоугольной площадке с разметкой для одиночного и парного матча.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Начало игры</h2>
          <p className="rules__article-description">Игра начинается с подачи. Игрок, выполняющий подачу, должен стоять за пределами поля за линией основания и попасть мячом в диагонально противоположную сервисную коробку.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Очки</h2>
          <p className="rules__article-description">Игра ведется до четырех выигранных очков с разницей в два очка. Очки считаются в следующем порядке: "0", "15", "30", "40". Если счет становится равным "40-40", это называется "деюс". После "деюса" для победы в гейме необходимо выиграть два очка подряд.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Выигрыш сета</h2>
          <p className="rules__article-description">Сет выигрывает тот игрок или пара, которая первой наберет шесть геймов с разницей в два гейма. Если счет в сете становится 6-6, играется тай-брейк.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Общие положения</h2>
          <p className="rules__article-description">Большой теннис — это спортивная игра, в которой могут участвовать два (одиночный матч) или четыре (парный матч) игрока. Игра проводится на прямоугольной площадке с разметкой для одиночного и парного матча.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Начало игры</h2>
          <p className="rules__article-description">Игра начинается с подачи. Игрок, выполняющий подачу, должен стоять за пределами поля за линией основания и попасть мячом в диагонально противоположную сервисную коробку.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Очки</h2>
          <p className="rules__article-description">Игра ведется до четырех выигранных очков с разницей в два очка. Очки считаются в следующем порядке: "0", "15", "30", "40". Если счет становится равным "40-40", это называется "деюс". После "деюса" для победы в гейме необходимо выиграть два очка подряд.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Выигрыш сета</h2>
          <p className="rules__article-description">Сет выигрывает тот игрок или пара, которая первой наберет шесть геймов с разницей в два гейма. Если счет в сете становится 6-6, играется тай-брейк.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Общие положения</h2>
          <p className="rules__article-description">Большой теннис — это спортивная игра, в которой могут участвовать два (одиночный матч) или четыре (парный матч) игрока. Игра проводится на прямоугольной площадке с разметкой для одиночного и парного матча.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Начало игры</h2>
          <p className="rules__article-description">Игра начинается с подачи. Игрок, выполняющий подачу, должен стоять за пределами поля за линией основания и попасть мячом в диагонально противоположную сервисную коробку.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Очки</h2>
          <p className="rules__article-description">Игра ведется до четырех выигранных очков с разницей в два очка. Очки считаются в следующем порядке: "0", "15", "30", "40". Если счет становится равным "40-40", это называется "деюс". После "деюса" для победы в гейме необходимо выиграть два очка подряд.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Выигрыш сета</h2>
          <p className="rules__article-description">Сет выигрывает тот игрок или пара, которая первой наберет шесть геймов с разницей в два гейма. Если счет в сете становится 6-6, играется тай-брейк.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Общие положения</h2>
          <p className="rules__article-description">Большой теннис — это спортивная игра, в которой могут участвовать два (одиночный матч) или четыре (парный матч) игрока. Игра проводится на прямоугольной площадке с разметкой для одиночного и парного матча.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Начало игры</h2>
          <p className="rules__article-description">Игра начинается с подачи. Игрок, выполняющий подачу, должен стоять за пределами поля за линией основания и попасть мячом в диагонально противоположную сервисную коробку.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Очки</h2>
          <p className="rules__article-description">Игра ведется до четырех выигранных очков с разницей в два очка. Очки считаются в следующем порядке: "0", "15", "30", "40". Если счет становится равным "40-40", это называется "деюс". После "деюса" для победы в гейме необходимо выиграть два очка подряд.</p>
        </section>
        <section className="rules__article-section">
          <h2 className="rules__article-title">Выигрыш сета</h2>
          <p className="rules__article-description">Сет выигрывает тот игрок или пара, которая первой наберет шесть геймов с разницей в два гейма. Если счет в сете становится 6-6, играется тай-брейк.</p>
        </section>
        <section className="rules__article-section">
          <h2>Тай-брейк</h2>
          <p className="rules__article-description">Тай-брейк — это особый гейм, который играется до семи выигранных очков с разницей в два очка. Подача в тай-брейке чередуется между игроками после каждых двух поданных мячей.</p>
        </section>
      </article>
    </main>
  );
});

export default GameRulesPage;

import { createContext } from 'react';

interface PopupContextType {
    openPopup: (id: string) => void;
    closePopup: (id: string) => void;
    openPopups: string[];
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export default PopupContext;

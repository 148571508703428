import { createSlice } from '@reduxjs/toolkit';

const UserInputSlice = createSlice({
  name: 'learningContext',
  initialState: {
    friendId: 0,
    friendMatchId: 0,
    isFriendlyMatchAccepted: false,
    isFriendlyMatchStarted: false,
  },
  reducers: {
    updateFriendlyUserId(state, action) {
      state.friendId = action.payload;
    },
    updateFriendMatchId(state, action) {
      state.friendMatchId = action.payload;
    },
    updateFriendlyMatchAccepted(state, action) {
      state.isFriendlyMatchAccepted = action.payload;
    },
    updateFriendlyMatchStarted(state, action) {
      state.isFriendlyMatchStarted = action.payload;
    },
  },
});

export const {
  updateFriendlyUserId,
  updateFriendMatchId,
  updateFriendlyMatchAccepted,
  updateFriendlyMatchStarted,
} = UserInputSlice.actions;
export default UserInputSlice.reducer;

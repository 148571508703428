import { memo } from 'react';
import './Loader.scss';

const Loader = memo(() => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
));

export default Loader;

import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Referal.scss';
import СopyIcon from '@img/copy.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BACKEND_URL } from '@constants/constants';

const Referal = memo(() => {
  const [referals, setReferals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const user = typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('user'));

  async function fetchReferals() {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${BACKEND_URL}/account/referrals/`);
      setReferals(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    fetchReferals();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://nft-tennis.com?ref=${user.id}`);
    toast.success(t('referal.success_link'), {
      position: 'bottom-right',
      className: 'referal__toast',
    });
  };

  return (
    <main className="main__referal">
      <article className="rules__article invite-page-wrap">
        <div className="copy-block-wrapper">
          <div className="copy-title-block">
            <h2 className="copy-title">{t('referal.title')}</h2>
            <p className="copy-description">{t('referal.description')}</p>
          </div>
          <div className="copy-block-list">
            {/* <div className='copy-block'>
              <div className="copy-block-receive">
                <div className="copy-block-receive-item">Друг получает 0%</div>
                <div className="copy-block-receive-item">Вы получаете 30%</div>
              </div>
            </div> */}
            <div className="copy-block">
              <h3 className="copy-block-title">
                {t('referal.link')}
              </h3>
              <div className="copy-block-content">
                <p className="copy-link">
                  https://nft-tennis.com?ref=
                  {user.id}
                </p>
                <СopyIcon className="copy-icon" width={25} onClick={handleCopyLink} />
              </div>
            </div>
            {/* <div className='copy-block'>
              <h3 className="copy-block-title">
                Реферальная ссылка
              </h3>
              <div className="copy-block-content">
                <p className="copy-link">https://nft-tennis.com/invite/123123</p>
                <СopyIcon className="copy-icon" width={25} />
              </div>
            </div> */}
          </div>
        </div>
        {referals && (
          <table className="main__referal-table">
            <thead>
              <tr>
                <td>Имя пользователя</td>
                <td>Заработано</td>
              </tr>
            </thead>
            <tbody>
              {referals.length > 0 ? referals.map((referal) => (
                <tr key={referal.id}>
                  <td>{referal.username}</td>
                  <td>{`${referal.income}$`}</td>
                </tr>
              )) : (
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </article>
    </main>
  );
});

export default Referal;

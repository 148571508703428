import type { FC } from 'react';
import {
  useRef, useState, useLayoutEffect, memo,
} from 'react';
import preview_advanced from '@img/cards/advanced_preview.png';
import preview_common from '@img/cards/common_preview.png';
import preview_rare from '@img/cards/rare_preview.png';
import preview_legendary from '@img/cards/legendary_preview.png';
import preview_unique from '@img/cards/unique_preview.png';
import traumaIcon from '@img/trauma-icon.png';
import { IoMdMale, IoMdFemale } from 'react-icons/io';

import { useHover } from '@hooks/useHover/useHover';
import LinearProgress from '@components/LinearProgress';
import type { TCharacteristicCard } from './types';
import { AddPlayerCard } from '../AddPlayerCard';

function getRarity(rarity: string) {
  switch (rarity) {
  case 'legendary':
    return ['bg-gradient-to-r from-red-400 to-red-700'];
  case 'rare':
    return ['bg-gradient-to-r from-blue-700 to-blue-400'];
  case 'advanced':
    return ['bg-gradient-to-r from-green to-emerald-600'];
  case 'common':
    return ['bg-gradient-to-r from-neutral-100 to-neutral-600'];
  case 'unique':
    return ['bg-gradient-to-r from-yellow-200 to-yellow-700'];
  }
}

const previewImages = {
  preview_unique,
  preview_legendary,
  preview_rare,
  preview_advanced,
  preview_common,
};

const CharacteristicCard: FC<TCharacteristicCard> = memo((props) => {
  const {
    card, isLearn = false, isNotFullWidth, style, styleHeadBlock, noAnimate,
  } = props;

  const ref = useRef(null);
  const [fontSize, setFontSize] = useState<number>(13);
  const [isHovered, { onMouseEnter, onMouseLeave }] = useHover();

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetWidth } = ref.current;
      setFontSize(offsetWidth / 10);
    }
  }, [ref.current]);

  if (!card) return <AddPlayerCard />;
  const rarity = card?.nft?.rarity?.toLowerCase() as | 'legendary'
    | 'rare'
    | 'advanced'
    | 'common'
    | 'unique'
  const [cardBG, gradient] = getRarity(rarity);
  const imagePreview = () => previewImages[`preview_${rarity}`]
  console.log(cardBG)

  const chrColor = rarity === 'legendary' || rarity === 'rare' || rarity === 'advanced' ? 'text-white' : 'text-black'

  if (isHovered) {
    return (
      <div
        className={`mystery-container transition-all shadow-cyan-500/50 w-full aspect-[1/1.6] relative text-white ${
          card?.trauma || isLearn ? 'card-trauma' : ''
        }`}
        style={{ fontSize, ...styleHeadBlock }}
        ref={ref}
      >
        <div
          className={`${isNotFullWidth ? '' : 'w-full'} h-full bg-cover rounded-md px-[.5em] ${
            card?.trauma || isLearn ? 'grayscale' : ''
          } absolute overflow-y-auto ${cardBG}`}
          style={{ backgroundImage: `url(${cardBG})`, ...styleHeadBlock, ...style, boxShadow: '4px 5px 27px 38px rgba(34, 60, 80, 0.51)' }}
          onMouseLeave={onMouseLeave}
        >
          <div className="flex justify-between items-center mb-3 pt-1">
            <div className="rounded-[4px] text-orange-400 px-2 py-[.2em] bg-black border-solid border-[1px] border-orange-400 text-[.5em]">
              #
              {card.circulation_number}
            </div>
            <div
              className={`rounded-md px-2 py-[.2em] text-[.5em] leading-tight ${gradient}`}
            >
              <p>rating</p>
              <div className="flex items-center justify-between">
                <div className="w-1/2">
                  {/* <FirstIcon /> */}
                </div>
                <p>{card.nft?.rating}</p>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/*        {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                  {card?.chr_reception}
                  {card?.chr_volley}
                  {card?.chr_stamina}
                  {card?.chr_talent}
                  {card?.chr_forehand} */}
          <ul>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>FOREHAND</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_forehand} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_forehand}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>BACKHAND</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_backhand} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_backhand}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>SERVE</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_serve} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_serve}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>RECEPTION</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_reception} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_reception}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>VOLLEY</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_volley} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_volley}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>STAMINA</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_stamina} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_stamina}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>TALENT</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_talent} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_talent}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>FOREHAND</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_forehand} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_forehand}
                  /100
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`mystery-container w-full aspect-[1/1.6] relative text-white ${
        card?.trauma || isLearn ? 'card-trauma' : ''
      }`}
      style={{ fontSize, ...styleHeadBlock }}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {(card.trauma || isLearn || card.on_team) && (
        <div
          className={`absolute -top-[1.5em] z-[1] text-[.5em] transform -translate-x-1/2 left-1/2 text-black border-[1px] border-black border-solid rounded-md px-[1em] w-fit ${
            card.trauma || isLearn
              ? 'bg-rose-400'
              : card?.on_team
                ? 'bg-green'
                : ''
          } `}
        >

          {(card.trauma || isLearn) ? (
            <div className="flex">
              <img
                className="w-[2em] h-[2em] inline"
                src={traumaIcon}
                alt="injury"
                loading="lazy"
              />
            </div>
          ) : card.on_team ? card?.on_team && (
            <div className="flex items-center justify-center text-[1.5em]">
              Team
            </div>
          ) : ''}
        </div>
      )}
      <div
        className={`${isNotFullWidth ? '' : 'w-full'} h-full bg-cover rounded-md px-[.5em] ${
          card?.trauma || isLearn ? 'grayscale' : ''
        } ${cardBG}`}
        style={{ backgroundImage: `url(${cardBG})`, ...styleHeadBlock, ...style }}
      >
        <div className="relative grid grid-rows-[10%_55%_35%] w-full h-full pt-1 pb-[.5em]">
          <div className="flex flex-row items-center justify-between">
            <div className="rounded-[4px] text-orange-400 px-2 py-[.2em] bg-black border-solid border-[1px] border-orange-400 text-[.5em]">
              #
              {card.circulation_number}
            </div>
            <div
              className={`rounded-md px-2 py-[.2em] text-[.5em] leading-tight ${gradient}`}
            >
              <p>rating</p>
              <div className="flex items-center justify-between">
                <div className="w-1/2">
                  {/* <FirstIcon /> */}
                </div>
                <p>{card.nft?.rating}</p>
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              className="absolute bottom-0 left-0 z-[1] w-full h-full max-w-full object-contain pointer-events-none"
              src={card?.nft?.image?.image}
              alt="player"
              loading="lazy"
            />
            <img
              className="m-[0_auto] h-full object-cover"
              src={imagePreview}
              alt="player_bg"
              loading="lazy"
            />
          </div>
          <div className="relative grid grid-rows-[25%_65%_10%]">
            <div className="relative text-center ">
              {/* {rarity === 'advanced' && (
                <img
                  className="absolute -top-[1em] left-1/2 transform -translate-x-1/2 w-5/6 text-center pointer-events-none z-[2]"
                  src={title_wrapper_advanced}
                  alt=""
                  width="100"
                  height="100"
                  loading="lazy"
                />
              )} */}
              {/* {rarity === 'unique' && (
                <img
                  className="absolute -top-[1.25em] left-1/2 transform -translate-x-1/2 w-5/6 text-center pointer-events-none z-[2]"
                  src={title_wrapper_unique}
                  alt=""
                  width="100"
                  height="100"
                  loading="lazy"
                />
              )} */}
              <h3
                className={`absolute uppercase z-[5] font-bold text-[.8em] left-1/2 transform -translate-x-1/2 w-fit
                    ${
    rarity === 'advanced'
                      && '[text-shadow:-1px_0_0_#000] -top-[.6em]'
    }
                    ${rarity === 'unique' && 'text-black -top-[.6em]'}
                    ${rarity === 'common' && 'text-black'}
                  `}
              >
                {card?.nft?.name}
              </h3>
            </div>
            {/* <div className="grid grid-cols-4 grid-rows-2 gap-x-1 gap-y-[.1rem]">
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <SecondIcon />
                  </div>
                  FOREHAND
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <ThirdIcon/>
                  </div>
                  BACKHAND
                </div>
                <div className="text-center text-[.5em] font-bold">
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <FourthIcon />
                  </div>
                  SERVE
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <FivethIcon />
                  </div>
                  RECEPTION
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                  {card?.chr_reception}
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <SixthIcon />
                  </div>
                  VOLLEY
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                  {card?.chr_reception}
                  {card?.chr_volley}
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <SeventhIcon />
                  </div>
                  STAMINA
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                  {card?.chr_reception}
                  {card?.chr_volley}
                  {card?.chr_stamina}
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <EightIcon />
                  </div>
                  TALENT
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                  {card?.chr_reception}
                  {card?.chr_volley}
                  {card?.chr_stamina}
                  {card?.chr_talent}
                </div>
              </div>
              <div className="flex flex-col py-[.1rem] mb-0 h-fit rounded-sm bg-gradient-to-r from-neutral-950 to-neutral-800">
                <div className="text-[.3em] flex items-center mx-auto gap-[.3em]">
                  <div className="w-[1em]">
                    <NinethIcon />
                  </div>
                  SPIRIT
                </div>
                <div className="text-center text-[.5em] font-bold">
                  {card?.chr_forehand}
                  {card?.chr_backhand}
                  {card?.chr_serve}
                  {card?.chr_reception}
                  {card?.chr_volley}
                  {card?.chr_stamina}
                  {card?.chr_talent}
                  {card?.chr_forehand}
                </div>
              </div>
            </div> */}
            <div
              className={`flex justify-center gap-[1em] w-full font-bold text-[.5em] items-center ${
                rarity === 'common'
                  ? 'text-black'
                  : rarity === 'unique'
                    ? 'text-black'
                    : ''
              }`}
            >
              <p className="flex items-center gap-[.5em]">
                {/* <img className="w-[1.5em] h-[1em]" src={flag} alt="" /> */}
                {card?.nft?.player_country?.toUpperCase()}
              </p>
              <p className="">
                AGE:
                {card?.nft?.player_age}
              </p>
              <div className="whitespace-nowrap">
                {(card?.player_type === 'ОДИНОЧНАЯ МУЖСКАЯ' || card?.player_type === 'ПАРНАЯ МУЖСКАЯ') ? <IoMdMale className="inline" /> : <IoMdFemale className="inline" />}
                {' '}
                {(card?.player_type === 'ПАРНАЯ МУЖСКАЯ' || card?.player_type === 'ПАРНАЯ ЖЕНСКАЯ') ? 'PARE' : 'SOLO'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CharacteristicCard;

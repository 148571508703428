import { useNavigate } from 'react-router-dom';
import {
  FC, useState, useContext, memo,
  useLayoutEffect,
} from 'react';
import logoImage from '@img/logo.png';
import { AuthContext } from '@context/AuthContext';
import { useDispatch } from 'react-redux';
import { updataActivePopup } from '@store/slices/IsActivePopupSlice';
import CancelGamePopup from '@screens/Training/ui/CancelGamePopup';
import './Logo.scss';

const Logo: FC<any> = memo((props) => {
  const { className, ...rest } = props;

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let tennisGameContinue: unknown;
  const [isPopupTennisGame, setIsPopupTennisGame] = useState(false);
  const [routeNavigation, setRouteNavigation] = useState('');

  useLayoutEffect(() => {
    tennisGameContinue = localStorage.getItem('startGame');
  }, []);

  return (
    <>
      <button
        aria-label="go to home page"
        type="button"
        {...rest}
        onClick={() => {
          if (user) {
            if (tennisGameContinue) {
              setIsPopupTennisGame(true);
              setRouteNavigation('/');
            } else if (user?.is_learned) { navigate('/', { replace: true }); } else { dispatch(updataActivePopup(true)); }
          } else {
            navigate('/', { replace: true });
          }
        }}
      >
        <img
          className="logo__image"
          src={logoImage}
          alt="logo"
          width="140"
          height="50"
          loading="lazy"
          title="to home page"
        />
      </button>
      <CancelGamePopup
        isActive={isPopupTennisGame}
        setIsActive={() => setIsPopupTennisGame(!isPopupTennisGame)}
        route={routeNavigation}
      />
    </>

  );
});

export default Logo;

import React, { ReactNode, useState } from 'react';
import PopupContext from '@context/PopupContext';

interface PopupProviderProps {
    children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [openPopups, setOpenPopups] = useState<string[]>([]);

  const openPopup = (id: string) => {
    setOpenPopups((prev) => [...prev, id]);
  };

  const closePopup = (id: string) => {
    setOpenPopups((prev) => prev.filter((popupId) => popupId !== id));
  };

  return (
    <PopupContext.Provider value={{ openPopup, closePopup, openPopups }}>
      {children}
    </PopupContext.Provider>
  );
};

import { KeyboardEvent, memo, useCallback, useEffect, useState } from "react";
import styles from './GameOverlay.module.scss'
import classNames from "classnames";
import CloseIcon from '@img/close.svg'
import { useNavigate } from "react-router-dom";
import ScreenIcon from '@img/screen.svg'

export const GameOverlay = memo(({ children, name, notRotateForMobile, closeWithReload }) => {
  const [isApproved, setIsApproved] = useState(false);
  const navigate = useNavigate();

  const handleApprove = useCallback(() => {
    setIsApproved(true)
  }, [])

  const handleCloseGame = useCallback(() => {
    if (closeWithReload) {
      window.location.href = '/training/parameters'
    } else {
      navigate(-1)
    }
  }, [navigate])

  useEffect(() => {
    function removeScroll(e: KeyboardEvent) {
      if (e.keyCode === 32 && e.target === document.body) {  
        e.preventDefault();  
      }  
    }
    // @ts-ignore
    window.addEventListener('keydown', (e) => removeScroll(e));

    return () => {
      window.removeEventListener('keydown', (e) => removeScroll(e));
    }
     
  })

  return (
    <div className={styles.GameOverlay}>
      <CloseIcon className={styles.closeIcon} onClick={handleCloseGame} />
      {!isApproved && <div className={styles.startBlock}>
            <h2 className={styles.startTitle}>{name}</h2>
            <p className={styles.startDescription}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut nihil ipsa aperiam fugiat cumque, facere modi aliquid aut eaque consectetur architecto reiciendis placeat quasi explicabo. Quasi, a sequi! Corrupti, facilis?
            </p>
            {!notRotateForMobile && (
              <div className={styles.rotateScreenBlock}>
                <h3 className={styles.rotateScreenTitle}>Переверните экран</h3>
                <p className={styles.rotateScreenDescription}>Игра требует широкоформатный формат экрана. Перед игрой убедитесь в корректном интернет подключении</p>
                <ScreenIcon className={styles.screenIcon} fill="#FFFFFF" />
              </div>

            )}
            <div className="main__button-outline mt-7">
              <button
                className="main__button main__button-outline_child"
                onClick={handleApprove}
              >
                Начать играть
              </button>
            </div>
          </div>}
      <div className={classNames(styles.content, {[styles.notRotateForMobile]: notRotateForMobile})}>
        {isApproved && children}
      </div>
    </div>
  )
})
const BACKEND_IP = 'nft-tennis.com'; // nft-tennis.com/api/
// const BACKEND_IP = "localhost"
const API_PREFEX = '/api';

const BACKEND_URL = `https://${BACKEND_IP}${API_PREFEX}`;
// const BACKEND_URL = "http://31.172.68.249:8000"
const BACKEND_WS_URL = `ws://${BACKEND_IP}${API_PREFEX}`;
const BACKEND_BASE_URL = `https://${BACKEND_IP}`;
//  local адрес
// const BACKEND_IP: string = "127.0.0.1:8000"
// const BACKEND_PORT: string = "85"

// const WA_BACKEND_PORT: string = "8006"

// const API_PREFEX: string = "/api"

// const BACKEND_URL: string = "http://" + BACKEND_IP + ":" + BACKEND_PORT + API_PREFEX;
// const BACKEND_WS_URL: string = "ws://" + BACKEND_IP + ":" + WA_BACKEND_PORT + API_PREFEX;

const ROUTE_MAIN = '/';
const ROUTE_MY_TEAMS = 'my_teams';
const ROUTE_SENDCODE = '/send_code';
const ROUTE_PROFILE = '/profile';
const ROUTE_CARDS_DROP = '/cards/drop';
const ROUTE_PROFILE_SUBSCRIPTIONS = 'subscriptions';
const ROUTE_PROFILE_SUBSSCRIBERS = 'subscribers';
const ROUTE_PROFILE_BALANCE_WITHDRAWAL = 'withdrawal';
const ROUTE_PROFILE_BALANCE_REPLENISH = 'replenish';
const ROUTE_TOURNAMENT_CALENDAR = '/calendar';
const ROUTE_LOGIN = '/login';
const ROUTE_CODE_CONFIRMATION = '/code_confirmation/';
const ROUTE_REGISTRATION_TWO = '/registration/two';
const ROUTE_MYCOMPOSITION = '/mycomposition';
const ROUTE_CARDS = '/profile/cards';
const ROUTE_ACHIEVEMENTS = '/profile/achievements';
const ROUTE_RESET_PASSWORD = '/reset_password';
const ROUTE_TOURNAMENT = '/tournaments';
const ROUTE_RATING = '/rating';
const ROUTE_PARTNER = '/become_partner';
const ROUTE_PARTNER_INFO = '/info_partner';
const ROUTE_TRAINING = '/training';
const ROUTE_MARKETPLACE = '/market/';
const ROUTE_MARKET_AUCTION = 'auction';
const ROUTE_GET_FIRST_CARDS = '/get_card';
const ROUTE_CONTROL_COMMAND = '/control-command';
const ROUTE_GAME_RULES = '/rules';
const ROUTE_TERMS_PAGE = '/terms';
const ROUTE_POLICY_PAGE = '/privacy';
const ROUTE_CONTACTS_PAGE = '/contacts';
const ROUTE_INVITE_PAGE = '/invite';
const ROUTE_CASE_PAGE = '/cases/:id';
const ROUTE_MYSTERY_CASE_PAGE = '/mysterycase/:id'

export {
  BACKEND_IP,
  // BACKEND_PORT,
  BACKEND_URL,
  BACKEND_WS_URL,
  ROUTE_PROFILE,
  ROUTE_PROFILE_SUBSCRIPTIONS,
  ROUTE_LOGIN,
  ROUTE_CODE_CONFIRMATION,
  ROUTE_PROFILE_SUBSSCRIBERS,
  ROUTE_MAIN,
  ROUTE_MY_TEAMS,
  ROUTE_SENDCODE,
  ROUTE_REGISTRATION_TWO,
  ROUTE_MYCOMPOSITION,
  ROUTE_ACHIEVEMENTS,
  ROUTE_CARDS,
  ROUTE_TOURNAMENT,
  ROUTE_RATING,
  ROUTE_RESET_PASSWORD,
  ROUTE_PARTNER,
  ROUTE_PROFILE_BALANCE_REPLENISH,
  ROUTE_PROFILE_BALANCE_WITHDRAWAL,
  ROUTE_TRAINING,
  ROUTE_CARDS_DROP,
  ROUTE_MARKETPLACE,
  ROUTE_TOURNAMENT_CALENDAR,
  ROUTE_MARKET_AUCTION,
  ROUTE_PARTNER_INFO,
  ROUTE_GET_FIRST_CARDS,
  BACKEND_BASE_URL,
  ROUTE_CONTROL_COMMAND,
  ROUTE_GAME_RULES,
  ROUTE_TERMS_PAGE,
  ROUTE_POLICY_PAGE,
  ROUTE_CONTACTS_PAGE,
  ROUTE_INVITE_PAGE,
  ROUTE_CASE_PAGE,
  ROUTE_MYSTERY_CASE_PAGE,
};

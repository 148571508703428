import { useDispatch, useSelector } from 'react-redux';
import {
  memo, useContext, useLayoutEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import discordIcon from '@img/discordIcon.png';
import telegramIcon from '@img/telegramIcon.png';
import { Link, useNavigate } from 'react-router-dom';
import {
  ROUTE_PARTNER,
  ROUTE_PARTNER_INFO,
  ROUTE_PROFILE,
} from '@constants/constants';
import EmailTimer from '@components/Timer/EmailTimer';
import PopupBox from '@components/PopupBox';
import usePopup from '@hooks/usePopup';
import { AuthContext } from '@context/AuthContext';
import LearnCancelPopup from '@screens/Learning/LearnCancelPopup';
import { updataActivePopup } from '@store/slices/IsActivePopupSlice';
import CancelGamePopup from '@screens/Training/ui/CancelGamePopup';
import './Footer.scss';

const Footer = memo(() => {
  const { t } = useTranslation();
  const { closePopup, openPopup } = usePopup();
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isActivePopupLearning = useSelector(
    (state: any) => state.learningContext.isActivePopup,
  );

  let tennisGameContinue: unknown;
  const [isPopupTennisGame, setIsPopupTennisGame] = useState(false);
  const [routeNavigation, setRouteNavigation] = useState('');

  useLayoutEffect(() => {
    tennisGameContinue = localStorage.getItem('startGame');
  }, []);

  return (
    <footer className="relative w-full flex m-[0_auto] flex-col bg-black text-standart">
      <div className="max-w-screen-xl w-full flex flex-col m-[0_auto] py-20 px-4">
        <div className="flex flex-col items-center justify-between gap-4 xl:flex-row">
          <div className="flex items-center order-last gap-4 w-fit xl:order-first">
            <a target="_blank" href="https://discord.gg/pUrcej9T" rel="noreferrer"><img className="object-contain max-w-10 max-h-10" src={discordIcon} alt="" /></a>
            <a target="_blank" href="https://t.me/NFTTennis" rel="noreferrer"><img className="object-contain max-w-10 max-h-10" src={telegramIcon} alt="" /></a>
          </div>
          <div className="flex flex-col gap-3 text-center xl:text-left">
            <Link className="text-[16px] inline-block" to="/terms">{t('footer.terms')}</Link>
            <Link className="text-[16px] inline-block" to="/privacy">{t('footer.privacy')}</Link>
            {/* <Link className="text-[16px] inline-block" to="/invite">{t('footer.invite')}</Link> */}
          </div>
          <div className="flex flex-col gap-3 text-center xl:text-left">
            <a href="javascript:jivo_api.open()" className="text-[16px] inline-block">{t('footer.help')}</a>
            <Link className="text-[16px] inline-block" to="/contacts">{t('footer.contacts')}</Link>
          </div>
          <div className="flex flex-col order-first gap-2 xl:order-4">
            <button
              className="py-4 text-xl btn-white gradient-green"
              type="button"
              onClick={() => {
                if (user) {
                  if (tennisGameContinue) {
                    setIsPopupTennisGame(true);
                    setRouteNavigation(`${ROUTE_PARTNER_INFO}`);
                  } else if (user?.is_learned) navigate(`${ROUTE_PARTNER_INFO}`, { replace: true });
                  else dispatch(updataActivePopup(true));
                } else {
                  navigate(`${ROUTE_PARTNER_INFO}`, { replace: true });
                }
              }}
            >
              {t('footer.affiliate')}
            </button>
          </div>
        </div>
        <p className="mt-24 w-full text-[14px] xl:text-right text-center ">
          {t('footer.copyright')}
        </p>
      </div>
      <EmailTimer />
      <PopupBox
        id="email_notfound"
        buttons={(
          <div className="flex w-full grid-cols-2 gap-4 xl:gap-12 flex-rows">
            <button
              className="relative border-2 w-full border-solid px-6 py-4 rounded-md bg-[transparent] border-green button-after-pink "
              onClick={() => closePopup('email_notfound')}
            >
              {t('popup.remind_later')}
            </button>
            <Link
              to={`${ROUTE_PROFILE}/${user?.id}/update`}
              className="relative w-full px-6 py-4 text-center text-black bg-white rounded-md shadow-rainbow"
              onClick={() => {
                closePopup('email_notfound');
              }}
            >
              {t('popup.go_to_settings')}
            </Link>
          </div>
        )}
      >
        <div className="font-bold text-white text-standart">{t('popup.empty_email')}</div>
        <div className="mt-4 text-white">
          {t('popup.link_email')}
        </div>
      </PopupBox>
      <LearnCancelPopup
        isActive={isActivePopupLearning}
        setIsActive={(value: boolean) => dispatch(updataActivePopup(value))}
      />
      <CancelGamePopup
        isActive={isPopupTennisGame}
        setIsActive={() => setIsPopupTennisGame(!isPopupTennisGame)}
        route={routeNavigation}
      />
    </footer>
  );
});

export default Footer;

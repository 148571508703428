import React, { useContext, useEffect, useRef } from 'react';
import usePopup from '@hooks/usePopup';
import { AuthContext } from '@context/AuthContext';

const EmailTimer: React.FC = () => {
  const { openPopup } = usePopup();
  const { user } = useContext(AuthContext);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeInterval = 30 * 60 * 1000;

  useEffect(() => {
    if (user && !user?.email) {
      // Проверка и открытие попапа при монтировании
      checkAndOpenPopup();

      // Установка интервала для регулярного открытия попапа
      intervalRef.current = setInterval(checkAndOpenPopup, timeInterval);
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [user]);

  function checkAndOpenPopup() {
    const storedTime = localStorage.getItem('lastExecutionTime');
    const currentTime = Date.now();

    if (storedTime) {
      const elapsed = currentTime - Number(storedTime);
      if (elapsed >= timeInterval) {
        openEmailPopup();
      }
    } else {
      openEmailPopup();
    }
  }

  function openEmailPopup() {
    openPopup('email_notfound');
    const currentTime = Date.now();
    localStorage.setItem('lastExecutionTime', String(currentTime));
  }

  return <></>;
};

export default EmailTimer;

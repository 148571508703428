import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ROUTE_MARKETPLACE,
  ROUTE_PROFILE,
  ROUTE_TOURNAMENT,
  ROUTE_RATING,
  ROUTE_MY_TEAMS,
  ROUTE_TRAINING,
  BACKEND_BASE_URL,
  ROUTE_CONTROL_COMMAND,
} from '@constants/constants';
import {
  memo, useContext, useEffect, useLayoutEffect, useState,
} from 'react';
import { AuthContext } from '@context/AuthContext';
import { NotificationContext } from '@context/NotificationContext';
import Logo from '@components/Logo';
import usePopup from '@hooks/usePopup';
import PopupBox from '@components/PopupBox';
import userAvatar from '@img/userAvatar.jpg';
import notification_icon from '@img/notification_icon.png';
import LearnCancelPopup from '@screens/Learning/LearnCancelPopup';
import { updataActivePopup } from '@store/slices/IsActivePopupSlice';
import CancelGamePopup from '@screens/Training/ui/CancelGamePopup';
import './Header.scss';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { getFormatMoney } from '@utils/getFormatMoney';

const hoverItemsClassName = 'hover:after:absolute hover:after:w-1/4 hover:after:h-1 hover:after:bg-green hover:after:-bottom-2 hover:after:left-1/3 hover:after:m-auto hover:after:rounded-3xl hover:after:rounded-[80%]';

const Header = memo((props: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { user, updateUser, logout } = useContext(AuthContext);
  const { notification_types } = useContext(NotificationContext);
  const { openPopup, closePopup } = usePopup();
  const [isSearchPopup, setIsSearchPopup] = useState(false);
  const [openMenuMob, setOpenMenuMob] = useState(false);
  const [openNavMob, setOpenNavMob] = useState(false);
  const [currency, setCurrency] = useState<{USDT: number}>({ USDT: user?.balance });


  useEffect(() => {
    setOpenMenuMob(false)
    setOpenNavMob(false)
  }, [navigate])

  const isActivePopupLearning = useSelector(
    (state: any) => state.learningContext.isActivePopup,
  );

  let tennisGameContinue: unknown;

  const [isPopupTennisGame, setIsPopupTennisGame] = useState(false);
  const [routeNavigation, setRouteNavigation] = useState('');

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleLanguageChange = (languageCode: any) => {
    i18n.changeLanguage(languageCode);
    props.setUpdate();
  };

  const handleOpenMenuMob = (to: string) => {
    navigate(to);
    setOpenMenuMob(false);
  };

  useEffect(() => {
    if (user) {
      updateUser();
    }
  }, [notification_types]); // Используйте здесь те зависимости, которые вызывают обновление пользователя

  function handleLogout() {
    logout();
    // localStorage.removeItem("RegisterOnTournament");
    // localStorage.removeItem("ReplacedCard");
    // localStorage.removeItem("startGame");
    navigate('/', { replace: true });
  }

  useLayoutEffect(() => {
    tennisGameContinue = localStorage.getItem('startGame');
  }, []);

  useEffect(() => {
    setCurrency({ USDT: user?.balance });
  }, [logout]);

  // useEffect(() => {
  //   axios.get(`${BACKEND_URL}/market/convert_currency/${Math.floor(user?.balance)}/USDT/`, {
  //     headers: {
  //       Authorization: `Bearer ${user?.token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setCurrency(response.data);
  //     })
  //     .catch((err) => console.error(err));
  // }, []);

  return (
    <>
      <div
        className={`fixed w-screen h-[100lvh] flex flex-col items-center gap-4 justify-center bg-neutral-950/90 ring-[1px] ring-inset ring-lime-300 rounded-xl z-[100] ${
          openNavMob ? '' : 'hidden'
        }`}
      >
        <button
          className="absolute top-4 right-6"
          onClick={() => setOpenNavMob(false)}
        >
          X
        </button>
        <li>
          <button
            className={`cursor-pointer relative  ${
              pathname === '/' && 'text-lime-300 font-bold'
            }`}
            type="button"
            onClick={() => {
              setOpenNavMob(false);
              if (tennisGameContinue) {
                setIsPopupTennisGame(true);
                setRouteNavigation('/');
              } else if (user?.is_learned) navigate('/', { replace: true });
              else dispatch(updataActivePopup(true));
            }}
          >
            {t('header.main')}
          </button>
        </li>
        <li>
          <button
            className={`cursor-pointer relative  ${
              pathname.includes('/market') && 'text-lime-300 font-bold'
            }`}
            type="button"
            onClick={() => {
              setOpenNavMob(false);
              if (tennisGameContinue) {
                setIsPopupTennisGame(true);
                setRouteNavigation(`${ROUTE_MARKETPLACE}cards`);
              } else if (user?.is_learned) {
                navigate(`${ROUTE_MARKETPLACE}cards`, {
                  replace: true,
                });
              } else dispatch(updataActivePopup(true));
            }}
          >
            {t('header.marketplace')}
          </button>
        </li>
        <li>
          <button
            className={`cursor-pointer relative  ${
              pathname === '/tournaments' && 'text-lime-300 font-bold'
            }`}
            type="button"
            onClick={() => {
              setOpenNavMob(false);
              if (tennisGameContinue) {
                setIsPopupTennisGame(true);
                setRouteNavigation(`${ROUTE_TOURNAMENT}`);
              } else if (user?.is_learned) navigate(`${ROUTE_TOURNAMENT}`, { replace: true });
              else dispatch(updataActivePopup(true));
            }}
          >
            {t('header.play')}
          </button>
        </li>
        <li>
          <button
            className={`cursor-pointer relative  ${
              pathname.includes('/rating')
                ? 'text-lime-300 text-lime-600 font-bold'
                : ''
            }`}
            type="button"
            onClick={() => {
              setOpenNavMob(false);
              if (tennisGameContinue) {
                setIsPopupTennisGame(true);
                setRouteNavigation(`${ROUTE_RATING}/users`);
              } else if (user?.is_learned) navigate(`${ROUTE_RATING}/users`, { replace: true });
              else dispatch(updataActivePopup(true));
            }}
          >
            {t('header.rating')}
          </button>
        </li>
        <li>
          <button
            className={`cursor-pointer relative  ${
              pathname === '/players'
              || pathname === '/profile/cards'
              || pathname === '/profile/achievements'
                ? 'text-lime-300 font-bold'
                : ''
            }`}
            type="button"
            onClick={() => {
              setOpenNavMob(false);
              if (tennisGameContinue) {
                setIsPopupTennisGame(true);
                setRouteNavigation(`${ROUTE_MY_TEAMS}`);
              } else if (user?.is_learned) navigate(`${ROUTE_MY_TEAMS}`, { replace: true });
              else dispatch(updataActivePopup(true));
            }}
          >
            {t('header.myClub')}
          </button>
        </li>
      </div>
      <header className="relative z-[80] flex items-center justify-between max-w-screen-xl px-4 pt-12 mx-auto xl:gap-16">
        <div className="absolute flex gap-4 right-4 top-4 text-green">
          <button
            onClick={() => handleLanguageChange('en')}
            className={i18n.language === 'en' ? 'decoration-1 underline' : ''}
          >
            EN
          </button>
          <button
            onClick={() => handleLanguageChange('ru')}
            className={i18n.language === 'ru' ? 'decoration-1 underline' : ''}
          >
            RU
          </button>
        </div>
        {user?.id && (
          <button
            className="flex flex-col justify-center w-12 h-16 gap-2 xl:hidden"
            onClick={() => setOpenNavMob(true)}
          >
            <div className="w-full h-[2px] rounded-md bg-lime-300" />
            <div className="w-full h-[2px] rounded-md bg-lime-300" />
            <div className="w-full h-[2px] rounded-md bg-lime-300" />
          </button>
        )}
        <div className="h-full ml-4 xl:w-36 xl:ml-0">
          <Logo />
        </div>
        {user?.id ? (
          <div className="relative flex items-center justify-end w-full gap-2 xl:gap-6 grow">
            <nav className="hidden w-full pr-4 xl:block">
              <ul className="flex items-center justify-between">
                <li>
                  <button
                    className={`cursor-pointer relative ${hoverItemsClassName} ${
                      pathname === '/'
                      && 'after:absolute after:w-1/4 after:h-1 after:bg-green after:-bottom-2 after:left-1/3 after:m-auto after:rounded-[80%]'
                    }`}
                    type="button"
                    onClick={() => {
                      if (tennisGameContinue) {
                        setIsPopupTennisGame(true);
                        setRouteNavigation('/');
                      } else if (user?.is_learned) navigate('/', { replace: true });
                      else dispatch(updataActivePopup(true));
                    }}
                  >
                    {t('header.main')}
                  </button>
                </li>
                <li>
                  <button
                    className={`cursor-pointer relative ${hoverItemsClassName}  ${
                      pathname.includes('/market')
                      && 'after:absolute after:w-1/4 after:h-1 after:bg-green after:-bottom-2 after:left-1/3 after:m-auto after:rounded-[80%]'
                    }`}
                    type="button"
                    onClick={() => {
                      if (tennisGameContinue) {
                        setIsPopupTennisGame(true);
                        setRouteNavigation(`${ROUTE_MARKETPLACE}cards`);
                      } else if (user?.is_learned) {
                        navigate(`${ROUTE_MARKETPLACE}cards`, {
                          replace: true,
                        });
                      } else dispatch(updataActivePopup(true));
                    }}
                  >
                    {t('header.marketplace')}
                  </button>
                </li>
                <li>
                  <button
                    className={`cursor-pointer relative ${hoverItemsClassName} ${
                      pathname === '/tournaments'
                      && 'after:absolute after:w-1/4 after:h-1 after:bg-green after:-bottom-2 after:left-1/3 after:m-auto after:rounded-[80%]'
                    }`}
                    type="button"
                    onClick={() => {
                      if (tennisGameContinue) {
                        setIsPopupTennisGame(true);
                        setRouteNavigation(`${ROUTE_TOURNAMENT}`);
                      } else if (user?.is_learned) navigate(`${ROUTE_TOURNAMENT}`, { replace: true });
                      else dispatch(updataActivePopup(true));
                    }}
                  >
                    {t('header.play')}
                  </button>
                </li>
                <li>
                  <button
                    className={`cursor-pointer relative ${hoverItemsClassName} ${
                      pathname.includes('/rating')
                        ? 'after:absolute after:w-1/4 after:h-1 after:bg-green after:-bottom-2 after:left-1/3 after:m-auto after:rounded-[80%]'
                        : ''
                    }`}
                    type="button"
                    onClick={() => {
                      if (tennisGameContinue) {
                        setIsPopupTennisGame(true);
                        setRouteNavigation(`${ROUTE_RATING}/users`);
                      } else if (user?.is_learned) navigate(`${ROUTE_RATING}/users`, { replace: true });
                      else dispatch(updataActivePopup(true));
                    }}
                  >
                    {t('header.rating')}
                  </button>
                </li>
                <li>
                  <button
                    className={`cursor-pointer relative ${hoverItemsClassName} ${
                      pathname === '/players'
                      || pathname === '/profile/cards'
                      || pathname === '/profile/achievements'
                        ? 'after:absolute after:w-1/4 after:h-1 after:bg-green after:-bottom-2 after:left-1/3 after:m-auto after:rounded-[80%]'
                        : ''
                    }`}
                    type="button"
                    onClick={() => {
                      if (tennisGameContinue) {
                        setIsPopupTennisGame(true);
                        setRouteNavigation(`/players`);
                      } else if (user?.is_learned) navigate(`/players`, { replace: true });
                      else dispatch(updataActivePopup(true));
                    }}
                  >
                    {t('header.myClub')}
                  </button>
                </li>
              </ul>
            </nav>
            <div className="flex flex-row items-center gap-4 xl:gap-10 max-w-fit">
              {/* <button
              className="relative cursor-pointer w-fit header__menu-search-icon"
              type="button"
              onClick={() => {
                if (tennisGameContinue) {
                  setIsPopupTennisGame(true);
                  setRouteNavigation(`${ROUTE_TRAINING}/parameters`);
                } else if (user?.is_learned) onSearchCards();
                else dispatch(updataActivePopup(true));
              }}
            >
              <SearchIcon />
            </button> */}
              <button
                className="transition-[.35s] xl:h-12 h-8  group flex items-center gap-[10px] border-2 border-solid border-green rounded-3xl px-4 py-2 hover:bg-green"
                type="button"
                onClick={() => {
                  if (tennisGameContinue) {
                    setIsPopupTennisGame(true);
                    setRouteNavigation(`${ROUTE_PROFILE}/balance/`);
                  } else if (user?.is_learned) navigate(`${ROUTE_PROFILE}/balance/`, { replace: true });
                  else dispatch(updataActivePopup(true));
                }}
              >
                <div style={{ marginTop: '-3px' }} className="aspect-square text-green group-hover:text-[#121212] font-bold">
                  $
                </div>
                <span className="xl:text-xl text-green group-hover:text-[#121212] font-bold text-nowrap">
                  {currency && getFormatMoney(currency.USDT)}
                </span>
              </button>
              <div className="relative group">
                <button
                  className="flex items-center gap-4 xl:w-24"
                  type="button"
                  onClick={() => {
                    if (tennisGameContinue) {
                      setIsPopupTennisGame(true);
                      setRouteNavigation(`${ROUTE_PROFILE}/${user?.id}`);
                    } else if (user?.is_learned) {
                      // navigate(`${ROUTE_PROFILE}/${user?.id}`, { replace: true });
                      setOpenMenuMob((prev) => !prev);
                    } else dispatch(updataActivePopup(true));
                  }}
                >
                  <img
                    className="relative object-cover h-12 rounded-full aspect-square"
                    src={
                      BACKEND_BASE_URL
                      + (user?.avatar ? user.avatar : userAvatar)
                    }
                    alt="avatar"
                  />
                  {notification_types.length > 0 ? (
                    <img
                      src={notification_icon}
                      className="absolute -right-4 -top-4"
                      alt="notification icon"
                    />
                  ) : (
                    ''
                  )}
                  <div
                    className={`w-2 h-2 border-b-2 border-l-2 border-solid border-b-lime-300 border-l-lime-300 transition-transform ${
                      openMenuMob ? 'rotate-[135deg]' : '-rotate-45'
                    }`}
                  />
                </button>
                {user?.is_learned
                  && tennisGameContinue !== 'true'
                  && openMenuMob && (
                  <div className="absolute right-0 py-2 text-white border border-solid rounded-md top-16 border-lime-300 bg-neutral-950/75">
                    <p className="px-4 py-2 text-2xl font-bold">
                      @
                      {user?.nickname}
                    </p>
                    <div
                      className={`${
                        pathname === `/profile/${user.id}`
                          ? 'bg-lime-300 font-bold text-black'
                          : ' whitespace-nowrap'
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob(`profile/${user.id}`)}
                    >
                      <span className="cursor-pointer">{t('profile.my_profile')}</span>
                    </div>
                    <div
                      className={`${
                        pathname === '/profile/referal'
                          ? 'bg-lime-300 font-bold text-black'
                          : ' whitespace-nowrap'
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob('/profile/referal')}
                    >
                      <span className="cursor-pointer">{t('profile.referal')}</span>
                    </div>
                    <div
                      className={`${
                        pathname === '/tournaments'
                          ? 'bg-lime-300 font-bold text-black'
                          : ' whitespace-nowrap'
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob('/tournaments')}
                    >
                      <span className="cursor-pointer">{t('profile.tournaments')}</span>
                    </div>
                    <div
                      className={`${
                        pathname === `${ROUTE_TRAINING}/parameters`
                          ? 'bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob(`${ROUTE_TRAINING}/parameters`)}
                    >
                      <span className="cursor-pointer">{t('profile.trainings')}</span>
                    </div>

                    <div
                      className={`${
                        pathname === `${ROUTE_CONTROL_COMMAND}/players/`
                          ? 'bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob(`${ROUTE_CONTROL_COMMAND}/players/`)}
                    >
                      <span className="cursor-pointer">{t('profile.control_teams')}</span>
                    </div>

                    <div
                      className={`${
                        pathname === '/profile/balance'
                          ? ' bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob('profile/balance')}
                    >
                      <span className="cursor-pointer">{t('profile.balance')}</span>
                    </div>

                    <div
                      className={`${
                        pathname === '/profile/transactions'
                          ? 'bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob('profile/transactions')}
                    >
                      <span className="cursor-pointer">{t('profile.transactions')}</span>
                    </div>

                    <div
                      className={`${
                        pathname === '/profile/notifications'
                          ? 'bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob('profile/notifications')}
                    >
                      <span className="cursor-pointer">{t('profile.notifications')}</span>
                      {' '}
                      {notification_types.length > 0 ? (
                        <img
                          src={notification_icon}
                          className="absolute -right-1 -top-1"
                          alt="notification_icon"
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div
                      className={`${
                        pathname === `${ROUTE_PROFILE}/${user?.id}/items`
                          ? 'bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob(`${ROUTE_PROFILE}/${user?.id}/items`)}
                    >
                      <span className="cursor-pointer">{t('profile.items')}</span>
                    </div>

                    <div
                      className={`${
                        pathname === `/profile/${user.id}/update`
                          ? 'bg-lime-300 font-bold text-black'
                          : ''
                      } text-lg w-full px-4 py-1 text-nowrap`}
                      onClick={() => handleOpenMenuMob(`profile/${user.id}/update`)}
                    >
                      <span className="cursor-pointer">{t('profile.settings')}</span>
                    </div>

                    <div
                      className="w-full px-4 py-1 text-lg"
                      onClick={() => {
                        openPopup('logout_popup-header');
                        setOpenMenuMob(false);
                      }}
                    >
                      <span className="cursor-pointer">{t('profile.logout')}</span>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="">
              <Link to="/login" className="">
                {t('auth.sign_in')}
              </Link>
            </div>
            {/* <div className="">
            <Link to={`${ROUTE_SENDCODE}`} className="">
              {t('auth.registration')}
            </Link>
          </div> */}
          </div>
        )}
        <PopupBox
          id="logout_popup-header"
          buttons={(
            <div className="flex w-full grid-cols-2 gap-4 xl:gap-12 flex-rows">
              <button
                className="relative border-2 w-full border-solid px-6 py-4 rounded-md bg-[transparent] border-green button-after-pink "
                onClick={() => closePopup('logout_popup-header')}
              >
                {t('cancel')}
              </button>
              <button
                className="relative w-full px-6 py-4 text-center text-black bg-white rounded-md shadow-rainbow"
                onClick={() => {
                  handleLogout();
                  closePopup('logout_popup-header');
                }}
              >
                {t('profile.logout')}
              </button>
            </div>
          )}
        >
          <div className="font-bold text-white ">{t('profile.logout')}</div>
          <div className="mt-4 text-white">{t('profile.sure')}</div>
        </PopupBox>
        <LearnCancelPopup
          isActive={isActivePopupLearning}
          setIsActive={(value: boolean) => dispatch(updataActivePopup(value))}
        />
        {/* <SearchCardsPopup
          isSearchPopup={isSearchPopup}
          setIsSearchPopup={setIsSearchPopup}
        /> */}
        <CancelGamePopup
          isActive={isPopupTennisGame}
          setIsActive={() => setIsPopupTennisGame(!isPopupTennisGame)}
          route={routeNavigation}
        />
      </header>
    </>
  );
});

export default Header;

// dispatch(updataActivePopup(true))

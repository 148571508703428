import { configureStore } from '@reduxjs/toolkit';
import userActivePopupReducer from './slices/IsActivePopupSlice';
import friendlyMatchResult from './slices/FriendlyMatchResult';
import timerReducer from './slices/Timer';
import friendIdReducer from './slices/friendlyMatchSlice';
import UpgradeItemOrStaffReducer from './slices/UpgradeSlice';
import isNewTeam from './slices/isNewTeam';
import TennisGameContinue from './slices/TennisGameContinue';

export const store = configureStore({
  reducer: {
    learningContext: userActivePopupReducer,
    timer: timerReducer,
    friendlyMatchResult,
    friendContext: friendIdReducer,
    upgradeItemOrStaff: UpgradeItemOrStaffReducer,
    isNewTeam,
    tennisGameContinue: TennisGameContinue,
  },
});

import { Navigate, Outlet } from 'react-router-dom';
import { UserModel } from '@models/User';
import { useContext } from 'react';
import { AuthContext } from '@context/AuthContext';

function ProtectedRouteForStudy() {
  const { user } = useContext(AuthContext);
  return (!user?.is_learned ? <Outlet /> : <Navigate to="/tournaments" replace />);
}

export default ProtectedRouteForStudy;
